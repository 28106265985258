<template>
  <IAMPage :isLoading="isLoading">
    <span slot="title">{{ title }}</span>
    <div>
      <IAMPanel :title="title" :is-collapsible="false">
       <span slot="toolbar-buttons">
        <div class="btn-group">
          <button aria-expanded="false" class="btn btn-default dropdown-toggle" data-toggle="dropdown">
            Acties <i class="caret" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a v-if="selectedProperties.length" @click="openPubActionModal">Pubacties registreren</a>
            </li>
            <li>
              <a title="Export modules" @click="exportModules">Modules exporteren</a>
            </li>
          </ul>
        </div>

        <a
          v-if="selectedProperties.length && selectedGroupId"
          class="btn btn-sm btn-danger"
          @click="removePropertiesFromGroup"
        >
          <i class="glyphicon glyphicon-trash" /> Panden verwijderen uit groep
         </a>

         <a @click="togglePublished" class="btn btn-sm btn-primary">
          <i class="glyphicon glyphicon-globe" /> {{ publishFilterButtonTitle }}
         </a>

         <div class="pull-right" style="width: 200px">
          <select v-model="selectedGroupId" class="form-control input-md">
            <option
              v-for="group in groups"
              :key="group.id"
              :value="group.id"
              v-html="group.name"
            />
          </select>
         </div>
         <div class="row" v-if="selectedGroupId">
            <div class="col-sm-6">
              <div
                v-if="selectedGroupId"
                class="form-group"
                style="text-align: left;"
              >
                <label class="control-label" style="float: left; margin-top: 8px;">Toevoegen aan groep</label>
                <MultiplePropertyPicker
                  ref="MultiplePropertyPicker"
                  v-model="newProperties"
                  :rapidMode="true"
                  @rapidSelect="handleRapidSelect"
                />
              </div>
            </div>
          </div>
       </span>
        <DataGrid
          ref="propertyList"
          :data="data"
          :columns="columns"
          :can-select="true"
          :search-fields="searchFields"
          @datagrid-item-selection-updated="selectProperties"
        />
      </IAMPanel>
    </div>
    <PublicationActionCreateModal ref="pubActionModal" :properties="selectedProperties" />
  </IAMPage>
</template>

<script>
import Vue from 'vue'

import Country from '@/components/iam/Country.vue'
import IAMPage from '@/components/iam/Page'
import IAMPanel from '@/components/iam/Panel'
import DataGrid from '@/components/iam/DataGrid'

import MultiplePropertyPicker from '@/components/properties/MultiplePropertyPicker'
import ReferenceHoverable from '@/components/iam/table_components/ReferenceHoverable.vue'
import PublicationActionCreateModal from '@/components/properties/PublicationActionCreateModal'

import { mapActions, mapState } from 'vuex'
import { getProperties, addPropertiesToGroup, deletePropertiesFromGroup, exportPropertyModules } from '@/services/properties'
import { errorModal, startLoadingModal, successModal, questionModal } from '@/modalMessages'

Vue.component('properties-type', {
  props: ['value', 'object'],
  template: '<span><span v-if="value.type">{{ value.type.name_nl}}</span></span>'
})

Vue.component('properties-city-display', {
  props: ['value', 'object'],
  template: '<span>{{ zip_code }} {{ city_name }}</span>',
  computed: {
    zip_code () {
      return this.object.city?.zip_code || ''
    },
    city_name () {
      return this.object.city?.name || ''
    }
  }
})

Vue.component('properties-country-display', {
  props: ['value', 'object'],
  template: '<span><Country :city="object.city" type="flag"></Country></span>',
  components: {
    Country
  }
})

Vue.component('properties-price-display', {
  props: ['value', 'object'],
  template: '<span><span v-if="object.price">&euro; {{ object.price.price }}</span></span>'
})

Vue.component('properties-address-display', {
  props: ['value', 'object'],
  template: '<span>{{ object.street }} {{ object.number }} {{ object.box }}</span>'
})

Vue.component('properties-published', {
  props: ['value', 'object'],
  template: '<span><span class="label label-primary" v-if="object.published">Gepubliceerd</span><span class="label label-danger" v-if="!object.published">Offline</span></span>'
})

export default {
  name: 'PropertyList',
  components: {
    PublicationActionCreateModal,
    MultiplePropertyPicker,
    IAMPage,
    IAMPanel,
    DataGrid
  },
  data () {
    return {
      id: 'PropertyList',
      selectedGroupId: this.$route.query?.group || '',
      data: [],
      columns: [
        { key: 'reference', title: 'Pand', renderComponent: ReferenceHoverable },
        { key: 'status_display_nl', title: 'Status' },
        { key: 'type', title: 'Type', renderComponent: 'properties-type' },
        { key: 'address', title: 'Adres', renderComponent: 'properties-address-display' },
        { key: 'city', title: 'Plaats', renderComponent: 'properties-city-display' },
        { key: 'country', title: 'Land', renderComponent: 'properties-country-display' },
        { key: 'price', title: 'Prijs', renderComponent: 'properties-price-display' },
        { key: 'publication', title: 'Publicatie', renderComponent: 'properties-published' }
      ],
      propertyCache: {},
      isLoading: false,
      showPublished: 'all',
      enableSearch: true,
      searchFields: ['reference', 'street'],
      newProperties: [],
      showCount: true,
      selectedProperties: []
    }
  },

  created () {
    this.getGroups()
  },
  watch: {
    selectedGroupId: {
      immediate: true,
      handler () {
        this.loadProperties()
      }
    },
    '$route.query.group' (groupId) {
      this.propertyCache = {}
      this.selectedGroupId = groupId
    },
    propertyCache () {
      this.data = this.filterProperties()
    },
    showPublished () {
      this.data = this.filterProperties()
    }
  },
  computed: {
    ...mapState('properties', ['groups']),

    publishFilterButtonTitle () {
      const titles = {
        all: 'Alle panden',
        published: 'Gepubliceerd',
        offline: 'Offline'
      }
      return titles[this.showPublished] || 'Alle panden'
    },
    title () {
      const groupName = this.groups && this.groups[this.$route.query.group]?.name
      return groupName || 'Panden'
    }
  },
  methods: {
    ...mapActions('properties', [
      'getGroups',
      'deletePropertyFromGroup'
    ]),

    async exportModules () {
      try {
        const selectedPropertyIds = this.$refs.propertyList.getSelectedRecords().map(prop => prop.id)
        if (!selectedPropertyIds?.length) return errorModal('Geen panden geselecteerd voor de export')
        const response = await exportPropertyModules({ property_ids: selectedPropertyIds.join(',') })
        successModal('De export is verstuurd naar je e-mailadres')
        return response
      } catch (e) {
        errorModal('Fout bij het aanmaken van de export')
      }
    },
    async removePropertiesFromGroup () {
      try {
        const result = await questionModal('Ben je zeker dat je dit pand uit de groep wilt verwijderen?')
        if (!result.value) return

        startLoadingModal('Je panden worden uit de groep verwijderd...')
        const properties = this.$refs.propertyList.getSelectedRecords()
        const payload = { property_id_list: properties.map(prop => prop.id) }
        await deletePropertiesFromGroup(this.selectedGroupId, payload)
        const response = await this.loadProperties()
        successModal('De wijzigingen zijn doorgevoerd')
        return response
      } catch (e) {
        console.error(e)
        errorModal('Er ging iets mis bij het verwijderen van je pand uit de groep, gelieve nogmaals te proberen')
      }
    },
    togglePublished () {
      if (this.showPublished === 'all') this.showPublished = 'published'
      else if (this.showPublished === 'published') this.showPublished = 'offline'
      else if (this.showPublished === 'offline') this.showPublished = 'all'
    },
    openPubActionModal () {
      this.$refs.pubActionModal.show()
    },
    selectProperties (records) {
      this.selectedProperties = records
    },
    filterProperties () {
      let cache
      if (this.showPublished === 'all') {
        cache = this.propertyCache
      }
      if (this.showPublished === 'published') {
        cache = Object.values(this.propertyCache).filter(prop => prop.published === true)
      }
      if (this.showPublished === 'offline') {
        cache = Object.values(this.propertyCache).filter(prop => prop.published === false)
      }
      return cache?.length ? cache.map(property => { return { ...property, type: 'property' } }) : []
    },
    async loadProperties () {
      try {
        if (!this.selectedGroupId) return errorModal('Geen groep geselecteerd, selecteer een groep.')
        this.isLoading = true
        const payload = { params: { groups: this.selectedGroupId } }
        const response = await getProperties(payload)
        this.propertyCache = response.data.results
        return response
      } catch (error) {
        errorModal('Fout bij het laden van eigenschappen, probeer het opnieuw.')
      } finally {
        this.isLoading = false
      }
    },
    async handleRapidSelect (property) {
      try {
        startLoadingModal()
        const groupId = this.selectedGroupId
        await addPropertiesToGroup(groupId, { property_id_list: [property.id] })
        const response = await this.loadProperties()
        successModal('Succesvol toegevoegd aan de groep')
        return response
      } catch (error) {
        errorModal('Kan niet toevoegen aan de groep, probeer het opnieuw')
      }
    }
  }
}
</script>
