<template>
  <span>
    <span v-if="type === 'name' && city && city.country">{{ city.country.name }}</span>
    <span v-if="type === 'iso' && city && city.country">{{city.country.iso_code}}</span>
    <span class="cflag" v-if="type === 'flag' && city && city.country" data-toggle="tooltip" data-placement="left"
          :title="city.country.name"><country-flag :country="city.country.iso_code.toLowerCase()"
                                                   size="small"></country-flag></span>
  </span>

</template>

<script>
import CountryFlag from 'vue-country-flag'

export default {
  name: 'Country',
  props: ['city', 'type'],
  components: {
    CountryFlag
  }
}
</script>

<style scoped>
  .cflag {
    transform: translateY(3px);
    display: inline-block;
  }
</style>
